import {Fragment} from "react";
import { PieChart } from '@mui/x-charts/PieChart';
import { BarChart } from '@mui/x-charts/BarChart';
import {useDashboard} from "./dashboardFunction";
import SearchComp from "../../component/globalComponent/SearchComp";
import CardComp from "../../component/globalComponent/CardComp";
import PropTypes from "prop-types";
import {SelectFilter} from "../Transaksi/TransaksiPage";

export const DashboardPage = (props) => {

    const { isLoading, dataStatus, dataTransaction, dataPaymentType, dataOutlet, pageValue, handleSearch } = useDashboard()
    return (
        <Fragment>
            <div className='bg-white drop-shadow-sm border border-gray-100 rounded-sm'>
                <div className='card-header px-5 py-3 font-semibold border-b border-gray-200'>DASHBOARD</div>
            </div>
            <div className="w-full p-4 mt-4 bg-white shadow-lg rounded-lg border border-gray-200">
                <h3 className="text-lg font-semibold mb-2">FILTER</h3>
                <div className="flex flex-wrap gap-4 mb-4">
                    <div className="flex-1 min-w-max">
                        <SelectFilter
                            onChange={handleSearch}
                            pageValue={pageValue}
                            name={'cabang_id'}
                            obj={pageValue.cabang_obj}
                        />
                    </div>
                    <div className="flex-1 min-w-max">
                        <SelectFilter
                            onChange={handleSearch}
                            pageValue={pageValue}
                            name={'month'}
                            obj={pageValue.month_obj}
                        />
                    </div>
                </div>
            </div>
            <div className="flex justify-between gap-4  mt-4">
                <div className="w-1/2 p-4 bg-white shadow-lg rounded-lg border border-gray-200">
                    <h3 className="text-lg font-semibold mb-2">STATUS PEMBAYARAN</h3>
                    <PieChart
                        width={400}
                        height={300}
                        series={[
                            {
                                data: dataStatus,
                                innerRadius: 30,
                                outerRadius: 100,
                                cornerRadius: 5,
                                cx: 150,
                                cy: 150,
                            },
                        ]}
                    />
                </div>
                <div className="w-1/2 p-4 bg-white shadow-lg rounded-lg border border-gray-200">
                    <h3 className="text-lg font-semibold mb-2">METODE PEMBAYARAN</h3>
                    <PieChart
                        width={600}
                        height={300}
                        series={[
                            {
                                data: dataPaymentType,
                                innerRadius: 30,
                                outerRadius: 100,
                                cornerRadius: 5,
                                cx: 150,
                                cy: 150,
                            },
                        ]}
                    />
                </div>
            </div>
            <div className="w-full p-4 mt-4 bg-white shadow-lg rounded-lg border border-gray-200">
                <h3 className="text-lg font-semibold mb-2">NILAI TRANSAKSI</h3>
                <BarChart
                    xAxis={[{scaleType: 'band', data: dataOutlet}]}
                    series={[{data: dataTransaction},]}
                    width={500}
                    height={300}
                />
            </div>
        </Fragment>
    )
}