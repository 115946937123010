import { Fragment } from "react"
import { useTransaksi } from "./transaksiFunction";
import AlertComp from "../../component/alert/AlertComp";
import CardComp from "../../component/globalComponent/CardComp";
import ButtonGroup from "../../component/globalComponent/ButtonGroup";
import SearchComp from "../../component/globalComponent/SearchComp";
import PagePagination from "../../component/globalComponent/PagePagination";
import ReactLoading from 'react-loading';
import PropTypes from 'prop-types'
import Button from "../../component/globalComponent/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPrint } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../component/Modal";
import FormTextSingleComp from "../../component/globalComponent/FormTextSingleComp";


export const SelectFilter = (props) => {
    const { onChange, pageValue, name, obj } = props;
    return (
        <select
            onChange={onChange}
            name={name}
            value={pageValue[name]}
            id="countries"
            className="bg-gray-50 
                border 
                border-gray-300 
                text-gray-900 
                text-sm 
                rounded-lg 
                focus:ring-blue-500 
                focus:border-blue-500 
                block w-full p-2.5 
                dark:bg-gray-700 dark:border-gray-600 
                dark:placeholder-gray-400 dark:text-white 
                dark:focus:ring-blue-500 
                dark:focus:border-blue-500
            ">
            {obj.map((post, index) => {
                return (
                    <option value={post.value} key={index}>{post.label}</option>
                )
            })}
        </select>
    )
}

SelectFilter.propTypes = {
    onChange: PropTypes.func.isRequired,
    pageValue: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    obj: PropTypes.array.isRequired,
}

const TransaksiPage = (props) => {
    const {
        isLoading, alertValue, handleCloseAlert,
        listData, handleCancel, handleSubmit,
        view, handleChangeSearch, handleSearch, handleKeyDownSearch, pageValue,
        handlePaginate, handleShowConfirm, modalValue, handleCloseModal, handleSubmitConfirm,
        formConfirm, handleChangeConfirm, handleBlurConfirm, handlePrint
    } = useTransaksi();

    const buttonNode = () => {
        return (
            <ButtonGroup>
                <button onClick={handleSubmit} className='button bg-red-500  h-full text-white'>Save</button>
                <button onClick={handleCancel} className='button bg-blue-500  h-full text-white'>Cancel</button>
            </ButtonGroup>
        )
    }

    const MyLabel = ({ isPaid }) => {
        return (
            <div className={`px-2 py-1 rounded-md text-center ${isPaid ? 'bg-green-200' : 'bg-red-200'} text-xs ${isPaid ? 'text-green-800' : 'text-red-800'}`} >
                {isPaid ? 'Paid' : 'Unpaid'}
            </div>
        )
    }

    const MyLabelCancel = ({ isPaid }) => {
        return (
            <div className={`px-2 py-1 rounded-md text-center ${!isPaid ? 'bg-green-200' : 'bg-red-200'} text-xs ${!isPaid ? 'text-green-800' : 'text-red-800'}`} >
                {isPaid ? 'Cancel' : 'Active'}
            </div>
        )
    }

    return (
        <Fragment>
            <AlertComp
                text={alertValue.text}
                color={alertValue.color}
                isOpen={alertValue.show}
                onClose={handleCloseAlert}
            />
            <CardComp title="Transaksi" ButtonNode={view === 'create' ? buttonNode : null}>
                {view === 'list' &&
                    <div className='px-5'>
                        <div className="grid grid-cols-5 gap-4 mb-4">
                            <div>
                                <SelectFilter
                                    onChange={handleChangeSearch}
                                    pageValue={pageValue}
                                    name={'cabang_id'}
                                    obj={pageValue.cabang_obj}
                                />
                            </div>
                            <div>
                                <SelectFilter
                                    onChange={handleChangeSearch}
                                    pageValue={pageValue}
                                    name={'paidState'}
                                    obj={pageValue.paidStateObj}
                                />
                            </div>
                            <div>
                                <SelectFilter
                                    onChange={handleChangeSearch}
                                    pageValue={pageValue}
                                    name={'cancelState'}
                                    obj={pageValue.cancelStateObj}
                                />
                            </div>
                            <div>
                                <SelectFilter
                                    onChange={handleChangeSearch}
                                    pageValue={pageValue}
                                    name={'methodPayment'}
                                    obj={pageValue.methodPaymentObj}
                                />
                            </div>
                            <SearchComp
                                pageValue={pageValue}
                                handleChangeSearch={handleChangeSearch}
                                handleSearch={handleSearch}
                                handleKeyDownSearch={handleKeyDownSearch}
                            />
                        </div>
                        <div className="relative">
                            <table className='table-auto w-full table md:table-fixed'>
                                <thead>
                                    <tr>
                                        <th className='text-right'>#</th>
                                        <th>No Invoice</th>
                                        <th>Invoice Origin</th>
                                        <th>Customer Name</th>
                                        <th>Payment Method</th>
                                        <th>Paid State</th>
                                        <th>Cancel State</th>
                                        <th>Confirm</th>
                                        <th>Confirm By</th>
                                        <th>Confirm Date</th>
                                        <th>Branch</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listData.map((post, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className='text-right'>{pageValue.offset + 1 + index}</td>
                                                <td>{post.noinvoice}</td>
                                                <td>{post.invoice_id_odoo}</td>
                                                <td>{post.konsumen_name}</td>
                                                <td>{post.payment_type_display}</td>
                                                <td>
                                                    <MyLabel
                                                        isPaid={post.is_paid}
                                                    />
                                                </td>
                                                <td>
                                                    <MyLabelCancel
                                                        isPaid={post.is_cancel}
                                                    />
                                                </td>
                                                <td>{post.is_confirm ? 'Confirmed' : 'Unconfirmed'}</td>
                                                <td>{post.confirm_by_display}</td>
                                                <td>{post.confirm_date_display}</td>
                                                <td>{post.outlet_display}</td>
                                                <td>
                                                    <ButtonGroup>
                                                        <Button className='button btn-icon bg-red-400 px-5 py-2 btn-danger text-white' color={(post.payment_type === 3 || post.payment_type === 4) && !post.is_confirm ? "success" : "success-disabled"} onClick={() => (post.payment_type === 3 || post.payment_type === 4) && !post.is_confirm ? handleShowConfirm(post) : null}>
                                                            <FontAwesomeIcon icon={faCheck}/>
                                                        </Button>
                                                        <Button className='button btn-icon bg-red-400 px-5 py-2 btn-danger text-white' color={"purple"} onClick={() => handlePrint(post)}>
                                                            <FontAwesomeIcon icon={faPrint}/>
                                                        </Button>
                                                    </ButtonGroup>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className='mt-5'>
                            <PagePagination
                                data={pageValue.obj}
                                handlePagination={handlePaginate}
                                page={pageValue.page}
                                lastPage={pageValue.lastPage}
                            />
                        </div>
                    </div>
                }
                {isLoading &&
                    <div className='w-full flex flex-row justify-center'>
                        <ReactLoading type={'cylon'} color="#0366fc" className="text-center" />
                    </div>
                }
            </CardComp>
            <Modal
                isOpen={modalValue.show}
                onClose={handleCloseModal}
                title={modalValue.title}
                onSubmit={handleSubmitConfirm}
                onSubmitText={"Confirm"}
            >
                <FormTextSingleComp
                    {...formConfirm.invoice_id_odoo}
                    readOnly={true}
                    onChange={handleChangeConfirm}
                    onBlur={handleBlurConfirm}
                />
                <FormTextSingleComp
                    {...formConfirm.invoice}
                    readOnly={true}
                    onChange={handleChangeConfirm}
                    onBlur={handleBlurConfirm}
                />
                <FormTextSingleComp
                    {...formConfirm.konsumen_name}
                    readOnly={true}
                    onChange={handleChangeConfirm}
                    onBlur={handleBlurConfirm}
                />
                <FormTextSingleComp
                    {...formConfirm.total}
                    readOnly={true}
                    onChange={handleChangeConfirm}
                    onBlur={handleBlurConfirm}
                    className={'text-right'}
                />
                <FormTextSingleComp
                    {...formConfirm.total_payment}
                    onChange={handleChangeConfirm}
                    onBlur={handleBlurConfirm}
                    readOnly={true}
                    className={'text-right'}
                />
                <FormTextSingleComp
                    {...formConfirm.changes}
                    onChange={handleChangeConfirm}
                    onBlur={handleBlurConfirm}
                    readOnly={true}
                    className={'text-right'}
                />
                <FormTextSingleComp
                    {...formConfirm.memo}
                    onChange={handleChangeConfirm}
                    onBlur={handleBlurConfirm}
                />
            </Modal>
        </Fragment>
    )
}
export default TransaksiPage;