import {useCallback, useEffect, useState} from "react";
import api from "../../api";
import config from "../../config";
import {pageObj} from "../../generalFunction/pageObj";
import {useGlobalFunction} from "../../generalFunction/generalFunction";

export const useDashboard = () => {

    const {
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal,
        isLoading, setIsLoading
    } = useGlobalFunction();

    const [dataStatus, setDataStatus] = useState([])
    const [dataPaymentType, setDataPaymentType] = useState([])
    const [dataOutlet, setDataOutlet] = useState([]);
    const [dataTransaction, setDataTransaction] = useState([])

    const fetchData = useCallback(async (outlet, month) => {
        setIsLoading(true);

        try {
            let params = {}
            if (outlet) {
                params['outlet'] = outlet
            }

            if (month) {
                params['month'] = month
            }

            const res = await api.post(`${config.endPoint.dashboard}`, {formData: JSON.stringify(params)}).then(res => res.data);
            if (res){
                setDataStatus(res.results.total_by_status)
                setDataPaymentType(res.results.total_by_payment)
                setDataTransaction(res.results.total_revenue_outlet.data_revenue)
                setDataOutlet(res.results.total_revenue_outlet.data_outlet)
            }
        } catch (error) {
            setIsLoading(false);
            let msg = "Unable to fetch data"
            let { response } = error;
            if (response && response.data && response.data.message){
                msg = response.data.message
            }
            setAlertValue(alertValue => ({...alertValue, show: true, text : msg, color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false }))
            }, config.timeOutValue)
        }

        setIsLoading(false);
    }, [setDataStatus, setDataPaymentType, setDataTransaction, setDataOutlet])

    const fetchcabang = useCallback(async(q='', offset=0, limit=config.itemPerPage) => {
        setIsLoading(true);
        try{
            let params = {
                offset : 0,
                limit : 300
            }
            const res = await api.get(`${config.endPoint.outlet}`, {params: params}).then(res => res.data);
            let tmp = [{value : '', label : '-- All Outlet --'}]
            res.results.map(post => tmp.push({value : post.id, label : post.nama}))
            // console.log('cabang length >>>', res.results)
            const currentDate = new Date();
            const currentMonthNumber = currentDate.getMonth() + 1;
            setPageValue(pageValue => ({...pageValue,
                cabang_obj : tmp,
                cabang_id : res.results.length === 1 ? res.results[0].id : '',
                month_obj: [
                    { value: 1, label: 'January' },
                    { value: 2, label: 'February' },
                    { value: 3, label: 'March' },
                    { value: 4, label: 'April' },
                    { value: 5, label: 'May' },
                    { value: 6, label: 'June' },
                    { value: 7, label: 'July' },
                    { value: 8, label: 'August' },
                    { value: 9, label: 'September' },
                    { value: 10, label: 'October' },
                    { value: 11, label: 'November' },
                    { value: 12, label: 'December' },
                ],
                month: currentMonthNumber
            }))
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            let msg = "Unable to fetch data"
            let { response } = error;
            if (response && response.data && response.data.message){
                msg = response.data.message
            }
            setAlertValue(alertValue => ({...alertValue, show: true, text : msg, color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false }))
            }, config.timeOutValue)
        }
    },[setPageValue, setIsLoading, setAlertValue])

    useEffect(() => {
        fetchcabang()
        fetchData()
    }, [fetchData, fetchcabang])

    const handleSearch = (e) => {
        const { name, value } = e.target;
        setPageValue(pageValue => ({...pageValue, [name] : value}))

    }

    useEffect(() => {
        fetchData(pageValue.cabang_id, pageValue.month)
    }, [pageValue]);

    return {
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal,
        isLoading, setIsLoading, dataStatus, dataPaymentType, dataTransaction, dataOutlet, handleSearch
    }

}