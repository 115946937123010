import { useState } from "react";

export function  useGlobalFunction(){
    const [isLoading, setIsLoading] = useState(false);
    const [alertValue, setAlertValue] = useState({
        show : false,
        text : '',
        color : 'danger'
    });
    const [modalValue, setModalValue] = useState({
        show: false,
        tipe: '',
        content : 'list',
        text : '',
        title : '',
        id : 0,
        params : null,
        tmpData : null
    })

    const handleCloseAlert = () => {
        setAlertValue(alertValue => ({...alertValue, show: false, text : ''}))
    }

    const [pageValue, setPageValue] = useState({
		page : 1,
		search : '',
		offset : 0,
		obj : [],
        cabang_id : '',
        cabang_obj : [],
        month_obj: [],
        month: null,
        tipe_harga_id : '',
        tipe_harga_obj : [],
        lastPage : 1,
        tipe_kontak : '',
        status_active : '',
        start_date : null,
        end_date: null,
        lokasi_id : '',
        lokasi_tujuan : '',
        lokasi_asal: '',
        perusahaan : 1,
        perusahaanObj : [],
        myIndex: 0,
        outlet : '',
        outletObj : [],
        orderBy : '',
        orderType : 'asc',
        expired_deposite : '',
        expired_deposite_obj : [
            {value : '', label : '-- Tampilkan Semua --'},
            {value : '1', label : 'Sudah Expired'},
            {value : '2', label : 'Expired bulan ini'},
        ],
        tahun : '',
        tahunObj : [],
        statusAktif : '',
        statusAktifObj : [
            {value : '', label : '-- Tampilkan Semua --'},
            {value : 1, label : 'Aktif'},
            {value : 2, label : 'Resign'},
            {value : 3, label : 'Diberhentikan'},
        ],
        paidState : '',
        paidStateObj : [{value : '', label : '-- Paid / Unpaid --'}, {value : 'paid', label : 'Paid'}, { value : 'unpaid', label : 'Unpaid' }],
        cancelState : '',
        cancelStateObj : [{value : '', label : '-- Cancel / Active --'}, {value : 'cancel', label : 'Cancel'}, { value : 'active', label : 'Active' }],
        methodPaymentObj : [{value : '', label : '-- All Method --'}, {value : '1', label : 'QRIS'}, { value : '2', label : 'Virtual Account' }, { value : '3', label : 'Bill Acceptor' }, { value : '4', label : 'EDC BCA' }, { value : '12', label : 'Cashless' }],
        methodPayment : '',
	});


    const handleCloseModal = () => {
        setModalValue({
            show: false,
            tipe: '',
            content : 'list',
            text : '',
            title : '',
        })
    }

    return {
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, 
        isLoading, setIsLoading
    }
}